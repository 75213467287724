import axios from 'axios';

export const getUser = async () => {
  const url = '/user/get';
  const response = await axios.get(url);
  return response.data;
};

export const getAllUser = async () => {
  const url = '/user/getAll';
  const response = await axios.get(url);
  return response.data;
};

export const updateUser = async (body) => {
  const url = '/user/update';
  const response = await axios.put(url, body);
  return response.data;
};

export const createUser = async (body) => {
  const url = '/user/create';
  const response = await axios.post(url, body);
  return response.data;
};

export const createBulkUser = async (body) => {
  const url = '/user/createbulk';
  const response = await axios.post(url, body);
  return response.data;
};

