import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import setupAxios from './api/axios';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './context/AuthContext';
// ----------------------------------------------------------------------
const AxiosInterceptorNavigate = () => {
  setupAxios(axios);
  return <></>;
};
const queryClient = new QueryClient();

export default function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <AxiosInterceptorNavigate />
              <ScrollToTop />
              <StyledChart />
              <Router />
            </ThemeProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  );
}
