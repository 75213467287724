import React, { useState } from "react";
import { Snackbar } from "@mui/material";

const withSnackbar = (WrappedComponent) => {
  return function WithSnackbar(props) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const openSnackbar = (message, severity) => {
      setMessage(message);
      setSeverity(severity);
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} showAlert={openSnackbar} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={2000}
          message={message}
          severity={severity}
          onClose={handleClose}
        />
      </>
    );
  };
};

export default withSnackbar;
