import axios from 'axios';

export const getkey = async () => {
  const url = '/gpt';
  const response = await axios.get(url);
  return response.data;
};

export const updateKey = async (body) => {
  const url = '/gpt/update';
  const response = await axios.post(url, body);
  return response.data;
};
