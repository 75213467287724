import axios from 'axios';

export const create = async (body) => {
  const url = '/prompt/create';
  const response = await axios.post(url, body);
  return response.data;
};

export const get = async (body) => {
  const url = '/prompt/getadmin';
  const response = await axios.get(url);
  return response.data;
};

export const update = async (body) => {
  const url = '/prompt/update';
  const response = await axios.put(url, body);
  return response.data;
};

export const deletePrompt = async (body) => {
  const url = `/prompt/delete/${body.id}`;
  const response = await axios.delete(url);
  return response.data;
};

export const getSpace = async () => {
  const url = '/space/get';
  const response = await axios.get(url);
  return response.data;
};
