import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { useMutation } from 'react-query';
import { loginUser } from 'src/api/authapi';
import withSnackbar from 'src/components/alert/alter';
import { useAuth } from 'src/context/AuthContext';

const LoginForm = ({ showAlert }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const { setIsAuthenticated } = useAuth();

  const { mutate } = useMutation(loginUser, {
    onSuccess: (res) => {
      localStorage.setItem('token', res.user.token);
      localStorage.setItem('user', res.user);
      setIsAuthenticated(true);
      navigate('/dashboard', { replace: true });
    },
    onError: (error) => {
      if (error.response && error.response.status === 401) {
        showAlert('Invalid credentials. Please try again.');
      } else {
        showAlert('An error occurred. Please try again later.');
      }
    },
  });

  const handleClick = () => {
    const body = {
      email: email,
      password: password,
      role: 'Admin',
    };
    mutate(body);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
};
export default withSnackbar(LoginForm);
