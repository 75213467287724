import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { deletePrompt } from 'src/api/promptapi';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

export default function DeletePrompt({ openCreate, setOpenCreate, userData, setUserData, refetch, handleCloseMenu }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpenCreate(false);
    setTimeout(() => setUserData(null), 500);
  };
  const { mutate, isLoading } = useMutation(deletePrompt, {
    onSuccess: () => {
      handleClose();
      refetch();
      handleCloseMenu();
    },
    onError: (error) => {
      console.error('Error deleting the user:', error);
    },
  });

  const handleDelete = () => {
    mutate({ id: userData?.id });
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCreate}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCreate}>
          <Box sx={style}>
            <Typography>Are you sure you want to delete?</Typography>
            <Stack direction="row" mt={4} spacing={2} justifyContent="flex-end">
              <Button size="small" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton onClick={handleDelete} size="small" type="submit" variant="contained" loading={isLoading}>
                Delete
              </LoadingButton>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
