import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { GridToolbarFilterButton, GridToolbarContainer } from '@mui/x-data-grid';
// @mui
import { Card, Stack, Button, Popover, MenuItem, Container, Typography, IconButton, Box } from '@mui/material';
import Iconify from '../components/iconify';

import CreateUser from '../components/user/create';
// ----------------------------------------------------------------------
import { useQuery, useMutation } from 'react-query';
import { getAllUser, createBulkUser } from 'src/api/userapi';
import { format, differenceInDays } from 'date-fns';

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [userData, setUserData] = useState(null);
  const [rows, setRows] = useState([]);
  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    console.log(row);
    setUserData(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const { data, refetch } = useQuery('getUsers', getAllUser, {
    refetchOnWindowFocus: false,
    onSuccess: () => {},
  });
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      editable: false,
      flex: 1,
    },
    {
      field: 'spaces',
      headerName: 'Teams',
      width: 190,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const teams = params.row.spaces.map((obj) => obj.name).join(', ');
        return (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={teams} // Adding a tooltip on hover to see full text
          >
            {teams}
          </div>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Joined Date',
      width: 110,
      editable: false,
      flex: 1,
      valueFormatter: (params) => {
        return params.value ? format(new Date(params.value), 'MMM dd, yyyy') : '';
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 80,
      editable: false,
      flex: 1,
    },
    {
      field: 'totalPrompt',
      headerName: 'Entered Prompt',
      width: 80,
      editable: false,
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 80,
      editable: false,
      flex: 1,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 80,
      editable: false,
      flex: 1,
    },
    {
      field: 'days',
      headerName: 'Days',
      width: 80,
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        const startDate = new Date(params.row.startDate);
        const endDate = new Date(params.row.endDate);
        return differenceInDays(endDate, startDate);
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;
        const capitalizedStatus = status?.charAt(0)?.toUpperCase() + status?.slice(1);
        return (
          <div style={{ color: status === 'joined' ? 'green' : status === 'pending' ? 'orange' : 'red' }}>
            {capitalizedStatus}
          </div>
        );
      },
    },
    {
      field: 'subscription',
      headerName: 'Plan',
      width: 110,
      editable: false,
      flex: 1,
    },
    {
      field: 'remarks',
      headerName: 'Remark',
      width: 110,
      editable: false,
      flex: 1,
    },
    {
      field: 'Action',
      headerName: '',
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params) => {
        return (
          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, params.row)}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        );
      },
    },
  ];
  const mutationFile = useMutation(createBulkUser, {
    onSuccess: (data) => {
      console.log('File uploaded successfully:', data);
      // queryClient.invalidateQueries('csvData');
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      mutationFile.mutate(file);
    }
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    const newArray = data?.data?.map((obj) => ({ ...obj, id: obj._id }));
    setRows(newArray);
  }, [data]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 2, direction: 'flex', justifyContent: 'space-between' }}>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <Helmet>
        <title> User | monkeyTeam </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Stack direction="row" alignItems="end" justifyContent="space-between" spacing={1}>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button variant="contained" onClick={handleButtonClick} startIcon={<Iconify icon="eva:plus-fill" />}>
              Bulk Invite
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenCreate(true)}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Invite User
            </Button>
          </Stack>
        </Stack>

        <Card sx={{ p: 1 }}>
          <Box sx={{ width: '100%' }}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-root': {
                  border: 0,
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none !important',
                },
              }}
              rowHeight={63}
              rows={rows ? rows : []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              slots={{
                toolbar: CustomToolbar,
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
            />
          </Box>
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenCreate(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
      <CreateUser
        openCreate={openCreate}
        setOpenCreate={setOpenCreate}
        userData={userData}
        setUserData={setUserData}
        refetch={refetch}
        handleCloseMenu={handleCloseMenu}
      />
    </>
  );
}
