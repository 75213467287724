import axios from 'axios';

export const create = async (body) => {
  const url = '/space/create';
  const response = await axios.post(url, body);
  return response.data;
};

export const get = async () => {
  const url = '/space/get';
  const response = await axios.get(url);
  return response.data;
};

export const getSpaceUser = async (spaceId) => {
  const url = `/space/getuser/${spaceId}`;
  const response = await axios.get(url);
  return response.data;
};

export const inviteUser = async (body) => {
  const url = '/space/invite';
  const response = await axios.post(url, body);
  return response.data;
};

export const removeUser = async ({ spaceId, userId }) => {
  const url = `/space/delete/${spaceId}/${userId}`;
  const response = await axios.delete(url);
  return response.data;
};

export const getAllSpace = async () => {
  const url = '/space/getAll';
  const response = await axios.get(url);
  return response.data;
};

export const addToSpace = async (body) => {
  const url = '/space/addToSpace';
  const response = await axios.post(url, body);
  return response.data;
};
