import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { Grid, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { createUser, updateUser } from 'src/api/userapi';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getAllSpace } from 'src/api/spaceapi';
import { useQuery } from 'react-query';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

export default function CreateUser({ openCreate, setOpenCreate, userData, setUserData, refetch, handleCloseMenu }) {
  const handleClose = () => {
    setOpenCreate(false);
    handleCloseMenu();
    setTimeout(() => setUserData(null), 500);
  };
  const { data } = useQuery('getspace', getAllSpace);

  const initialValues = {
    name: userData?.name || '',
    email: userData?.email || '',
    status: userData?.status || '',
    subscription: userData?.subscription || '',
    space: userData?.spaces.map((spa) => spa.id) || [],
    startDate: userData?.startDate || '',
    endDate: userData?.endDate || '',
    id: userData?._id,
    remarks: userData?.remarks || '',
    role: userData?.role || '',
    // status: '',
  };

  const userSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is  required'),
    space: Yup.array().min(1).required('Space is  required'),
    // status: Yup.string().required('Status is  required'),
    // status: Yup.number().required('Status is  required'),
  });

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    const user = userData ? updateUser(values) : createUser(values);
    user
      .then((response) => {
        setSubmitting(false);
        handleClose();
        refetch();
        handleCloseMenu();
      })
      .catch(() => {
        setSubmitting(false);
        setErrors({ message: 'User not created' });
        handleClose();
        handleCloseMenu();
      });
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCreate}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCreate}>
          <Box sx={style}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={userSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, handleSubmit, setFieldValue, isSubmitting, getFieldProps, dirty, values }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2.1}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        fullWidth
                        label="Name"
                        {...getFieldProps('name')}
                        variant="standard"
                        error={Boolean(touched?.name && errors?.name)}
                        helperText={touched?.name && errors?.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="eamil"
                        fullWidth
                        label="Email"
                        multiline
                        {...getFieldProps('email')}
                        variant="standard"
                        error={Boolean(touched?.email && errors?.email)}
                        helperText={touched?.email && errors?.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values?.role}
                          label="Role"
                          onChange={(e) => setFieldValue('role', e.target.value)}
                        >
                          <MenuItem value={'Admin'}>Admin</MenuItem>
                          <MenuItem value={'Member'}>Member</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="startDate"
                        type="date"
                        fullWidth
                        label="Start Date"
                        InputLabelProps={{ shrink: true }}
                        {...getFieldProps('startDate')}
                        variant="standard"
                        error={Boolean(touched.startDate && errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="endDate"
                        type="date"
                        fullWidth
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        {...getFieldProps('endDate')}
                        variant="standard"
                        error={Boolean(touched.endDate && errors.endDate)}
                        helperText={touched.endDate && errors.endDate}
                      />
                    </Grid>
                    {!userData && (
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">Team</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values?.space}
                            label="Space"
                            multiple
                            onChange={(e) => setFieldValue('space', e.target.value)}
                            error={Boolean(touched?.space && errors?.space)}
                            helperText={touched?.space && errors?.space}
                          >
                            <MenuItem value={'personal'}>Personal Space</MenuItem>
                            {data?.data?.map((space, index) => (
                              <MenuItem key={index} value={space._id}>
                                {space.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {userData && (
                      <>
                        <Grid item xs={12} md={12}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="demo-simple-select-label">Team</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={values?.space}
                              label="Space"
                              multiple
                              onChange={(e) => setFieldValue('space', e.target.value)}
                              error={Boolean(touched?.space && errors?.space)}
                              helperText={touched?.space && errors?.space}
                            >
                              <MenuItem value={'personal'}>Personal Space</MenuItem>
                              {data?.data?.map((space, index) => (
                                <MenuItem key={index} value={space._id}>
                                  {space.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={values?.status}
                              label="Status"
                              onChange={(e) => setFieldValue('status', e.target.value)}
                            >
                              <MenuItem value={'pending'}>Pending</MenuItem>
                              <MenuItem value={'joined'}>Joined</MenuItem>
                              <MenuItem value={'banned'}>Banned</MenuItem>
                              {/* <MenuItem value={'expired'}>Yearly</MenuItem> */}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values?.subscription}
                          label="Plan"
                          onChange={(e) => setFieldValue('subscription', e.target.value)}
                        >
                          <MenuItem value={'Free'}>Free</MenuItem>
                          <MenuItem value={'Referal'}>Referal</MenuItem>
                          <MenuItem value={'Paid'}>Paid</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        fullWidth
                        label="Remark"
                        multiline
                        {...getFieldProps('remarks')}
                        variant="standard"
                        error={Boolean(touched?.remarks && errors?.remarks)}
                        helperText={touched?.remarks && errors?.remarks}
                      />
                    </Grid>
                  </Grid>
                  <Stack direction="row" mt={4} spacing={2} justifyContent="flex-end">
                    <Button size="small" onClick={handleClose}>
                      Cancel
                    </Button>
                    <LoadingButton
                      size="small"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!dirty}
                    >
                      {'save'}
                    </LoadingButton>
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
