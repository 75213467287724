import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Formik, Form, Field } from 'formik';
import { TextField, InputAdornment, IconButton, Container, Stack, Typography, Card, Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { getkey, updateKey } from 'src/api/gptapi';
import { useQuery } from 'react-query';
const GptPage = () => {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle the visibility of the password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { data, refetch } = useQuery('get', getkey, {
    refetchOnWindowFocus: false,
  });
  const handleSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
    const user = updateKey(values);
    user
      .then((response) => {
        setSubmitting(false);
        refetch();
        resetForm({
          values: values,
        });
      })
      .catch(() => {
        setSubmitting(false);
        setErrors({ message: 'Error' });
      });
  };
  return (
    <>
      <Helmet>
        <title>API Key</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
          <Typography variant="h4" gutterBottom>
            API Key
          </Typography>
        </Stack>
        <Card sx={{ p: 4, m: 8 }}>
          <Formik
            initialValues={{ key: data?.key || '', id: data?._id, upgradeLink: data?.upgradeLink || '' }}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleSubmit, isSubmitting, getFieldProps, dirty }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2.1} justifyContent="center">
                  <Grid item xs={12} md={6}>
                    <Field name="password">
                      {({ field }) => (
                        <Grid container spacing={2.1}>
                          <Grid item xs={12}>
                            <TextField
                              {...field}
                              type={showPassword ? 'text' : 'password'}
                              label="API KEY"
                              variant="outlined"
                              fullWidth // Ensures the TextField takes the full width of the Grid item
                              {...getFieldProps('key')}
                              error={Boolean(touched?.key && errors?.key)}
                              helperText={touched?.key && errors?.key}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              {...field}
                              type="text"
                              label="Upgrade Link"
                              variant="outlined"
                              fullWidth // Ensures the TextField takes the full width of the Grid item
                              {...getFieldProps('upgradeLink')}
                              error={Boolean(touched?.upgradeLink && errors?.upgradeLink)}
                              helperText={touched?.upgradeLink && errors?.upgradeLink}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Stack direction="row" mt={4} spacing={2} justifyContent="center">
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={!dirty}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </>
  );
};

export default GptPage;
