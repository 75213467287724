export default function setupAxios(axios, history) {
  axios.defaults.baseURL = 'https://api.monkeyteam.io/api';
  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('token');
      if (accessToken) {
        config.headers.Authorization = `${accessToken}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response && error.response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        if (history) {
          history.push('/login');
        } else {
          window.location.href = '/login';
        }
      }
      return Promise.reject(error);
    }
  );
}
