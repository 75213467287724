import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { useMutation } from "react-query";
import { registerUser } from "src/api/authapi";
import withSnackbar from "src/components/alert/alter"; // assuming you saved the HOC in a file called withSnackbar

// ----------------------------------------------------------------------

const SignupForm = ({ showAlert }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { mutate } = useMutation(registerUser, {
    onSuccess: () => {
      showAlert("Successfully Register, login to contimue");
      navigate("/login", { replace: true });
    },
    onError: (error) => {
      if (error.response && error.response.status === 401) {
        showAlert("Invalid credentials. Please try again.");
      } else {
        showAlert("An error occurred. Please try again later.");
      }
    },
  });
  const handleClick = () => {
    const body = {
      name: name,
      email: email,
      password: password,
    };
    mutate(body);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="name"
          type="text"
          label="Full name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextField
          name="email"
          type="email"
          label="Email address"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
      >
        Signup
      </LoadingButton>
    </>
  );
};

export default withSnackbar(SignupForm);
