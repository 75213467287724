import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { Grid, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { create, update, getSpace } from 'src/api/promptapi';
import { useQuery } from 'react-query';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

export default function CreatePrompt({
  openCreate,
  setOpenCreate,
  promptData,
  setPromptData,
  refetch,
  handleCloseMenu,
}) {
  const handleClose = () => {
    setOpenCreate(false);
    setPromptData(null);

    handleCloseMenu();
  };
  const initialValues = {
    id: promptData?._id || '',
    name: promptData?.name || '',
    prompt: promptData?.prompt || '',
    spaceId: promptData?.spaceId || [],
    space: '',
    role: 'Admin',
  };

  const userSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    prompt: Yup.string().required('Prompt is  required'),
  });

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    const space = data?.data?.filter((sp) => values.spaceId.includes(sp.id));
    values.space = space.map((sp) => sp.name);
    const user = promptData ? update(values) : create(values);
    user
      .then((response) => {
        setSubmitting(false);
        refetch();
        handleClose();
        handleCloseMenu();
      })
      .catch(() => {
        setSubmitting(false);
        setErrors({ message: 'User not created' });
        handleClose();
        handleCloseMenu();
      });
  };
  const { data } = useQuery('getspace', getSpace);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCreate}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCreate}>
          <Box sx={style}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={userSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, handleSubmit, setFieldValue, isSubmitting, getFieldProps, dirty, values }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2.1}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        fullWidth
                        label="Name"
                        {...getFieldProps('name')}
                        variant="outlined"
                        error={Boolean(touched?.name && errors?.name)}
                        helperText={touched?.name && errors?.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="prompt"
                        fullWidth
                        label="Prompt"
                        multiline
                        minRows={5}
                        {...getFieldProps('prompt')}
                        variant="outlined"
                        error={Boolean(touched?.prompt && errors?.prompt)}
                        helperText={touched?.prompt && errors?.prompt}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="start">
                        //       <Tooltip title="Copy">
                        //         <IconButton>
                        //           <ContentCopyIcon onClick={() => handleCopy(values.prompt)} />
                        //         </IconButton>
                        //       </Tooltip>
                        //     </InputAdornment>
                        //   )
                        // }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">Space</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values?.spaceId}
                          label="Space"
                          multiple
                          onChange={(e) => setFieldValue('spaceId', e.target.value)}
                        >
                          {data?.data?.map((space, index) => (
                            <MenuItem value={space.id}>{space.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Stack direction="row" mt={4} spacing={2} justifyContent="flex-end">
                    <Button size="small" variant="contained" color="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <LoadingButton
                      size="small"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={!dirty}
                    >
                      {promptData ? 'Update' : 'Save'}
                    </LoadingButton>
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
