import axios from 'axios';

export const registerUser = async (body) => {
  const url = '/auth/register';
  const response = await axios.post(url, body);
  return response.data;
};

export const loginUser = async (body) => {
  const url = '/auth/login';
  const response = await axios.post(url, body);
  return response.data;
};
